require('./bootstrap');

require('trumbowyg');

jQuery(document).ready(function($){

    // Gestion des éditeurs wysiwyg

    var origin   = window.location.origin;

    $.trumbowyg.svgPath = origin + '/img/icons.svg';

    $('#full-editor').trumbowyg({
        btnsDef: {
            customformatting: {
                dropdown: ['p', 'blockquote', 'h2', 'h3', 'h4'],
                ico: 'p',
                hasIcon: true
            }
        },
        btns: [
            ['viewHTML'],
            ['customformatting'],
            ['strong', 'em', 'del'],
            ['superscript', 'subscript'],
            ['insertImage', 'link'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['horizontalRule'],
            ['removeformat'],
            ['fullscreen']
        ]
    });

    $('#simple-editor').trumbowyg({
        btnsDef: {
            customformatting: {
                dropdown: ['p', 'blockquote', 'h2', 'h3', 'h4'],
                ico: 'p',
                hasIcon: true
            }
        },
        btns: [
            ['viewHTML'],
            ['customformatting'],
            ['strong', 'em'],
            ['link'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull']
        ]

    });

    $('#light-editor').trumbowyg({
        btns: [
            ['viewHTML'],
            ['strong', 'em']
        ]
    });

    $('.alert-supp').click(function(e) {
        if (confirm("Voulez-vous supprimer ?"))
        {
            $(".alert-supp").confirm();

        } else {
            return false;
        }
    });

});





